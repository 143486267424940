import dayjs from 'dayjs'

function showModal(el) {
  const modal = document.querySelector(el)
  modal.classList.add('visible')
  modal.classList.remove('invisible')
}

function hideModal(el) {
  const modal = document.querySelector(el)
  modal.classList.remove('visible')
  modal.classList.add('invisible')

  if (modal.dataset.modalReset) {
    let form = modal.querySelector('form')

    if (form) {
      form.reset()
    }
  }
}

function showToastSuccess(message) {
  showToast('.toast-success', message)
}

function showToastError(message) {
  showToast('.toast-error', message)
}

function showToastReject(message) {
  showToast('.toast-reject', message)
}


function showToast(el, message) {
  if (Array.isArray(message)) {
    message = message.join('<br>')
    console.log(message)
  }

  const toast = document.querySelector(el)
  toast.querySelector('.toast-message').innerHTML = message
  toast.classList.add('visible')
  toast.classList.remove('invisible')

  setTimeout(() => {
    hideToast(el)
  }, 10000)
}

function hideToastSuccess() {
  hideToast('.toast-success')
}

function hideToastError() {
  hideToast('.toast-error')
}

function hideToastReject() {
  hideToast('.toast-reject')
}

function hideToast(el) {
  const toast = document.querySelector(el)
  toast.querySelector('.toast-message').textContent = ''
  toast.classList.add('invisible')
  toast.classList.remove('visible')
}

function flashMessage(type, message) {
  sessionStorage.setItem(type, message)
}

function createDate(year, month, day) {
  let date = dayjs()
  date = date.year(year).month(month)
  let endOfMonth = date.endOf('month').date()

  if (day > endOfMonth || day === 0) {
    day = endOfMonth
  }

  date = date.date(day)
  return date
}

function normalizeErrors(errors) {
  // Show first error only
  let formErrors = {}
  for (let [field, errors] of Object.entries(errors)) {
    formErrors[field] = errors[0]
  }
  return formErrors
}

// https://github.com/jquense/yup/issues/1330#issuecomment-901170542
function transformNan(value) {
  return isNaN(value) || value === null ? null : value
}

function popupCenter({ url, w, h }) {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height

  const systemZoom = width / window.screen.availWidth
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop
  const newWindow = window.open(
    url,
    'popup',
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  )

  if (window.focus) newWindow.focus()
}

// Payroll Utils
const computeSemiMonthlyCycle = ({ year, month, startDay, endDay, payoutDay, cycle }) => {
  // Day.js months are 0 indexed
  let startDate = createDate(year, month - 1, startDay)
  let endDate = createDate(year, month - 1, endDay)
  let payoutDate = createDate(year, month - 1, payoutDay)

  if (cycle === 1) {
    if (payoutDate < startDate) {
      startDate = startDate.subtract(1, 'month')
    }
    if (payoutDate < endDate) {
      endDate = endDate.subtract(1, 'month')
    }
  } else if (cycle === 2) {
    if (endDate < startDate) {
      endDate = endDate.add(1, 'month')
    }
    if (payoutDate < endDate) {
      payoutDate = payoutDate.add(1, 'month')
    }
  }

  return { startDate, endDate, payoutDate }
}

export {
  showModal,
  hideModal,
  showToastSuccess,
  hideToastSuccess,
  showToastError,
  hideToastError,
  hideToastReject,
  showToastReject,
  flashMessage,
  createDate,
  normalizeErrors,
  transformNan,
  popupCenter,
  computeSemiMonthlyCycle,
}
